import React from "react"
import Racepage from "../components/racepage"

const Flanders2020 = () => {
  const startlist = [
    {
      firstname: " Leonardo",
      lastname: " BONIFAZIO",
      number: " 247",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Dries",
      lastname: " VAN GESTEL",
      number: " 246",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Anthony",
      lastname: " TURGIS",
      number: " 245",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Adrien",
      lastname: " PETIT",
      number: " 244",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Florian",
      lastname: " MAITRE",
      number: " 243",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Damien",
      lastname: " GAUDIN",
      number: " 242",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Niki",
      lastname: " TERPSTRA",
      number: " 241",
      team: " Team Total Direct Energie",
    },
    {
      firstname: " Luca",
      lastname: " MOZZATO",
      number: " 237",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Jérémy",
      lastname: " LECROQ",
      number: " 236",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Jens",
      lastname: " DEBUSSCHERE",
      number: " 235",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Bert",
      lastname: " DE BACKER",
      number: " 234",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Bryan",
      lastname: " COQUARD",
      number: " 233",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Cyril",
      lastname: " BARTHE",
      number: " 232",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Frederik",
      lastname: " BACKAERT",
      number: " 231",
      team: " B&B Hotels - Vital Concept p/b KTM",
    },
    {
      firstname: " Kenneth",
      lastname: " VAN ROOY",
      number: " 227",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Aaron",
      lastname: " VAN POUCKE",
      number: " 226",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Fabio",
      lastname: " VAN DEN BOSSCHE",
      number: " 225",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Thomas",
      lastname: " SPRENGERS",
      number: " 224",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Gilles",
      lastname: " DE WILDE",
      number: " 223",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Amaury",
      lastname: " CAPIOT",
      number: " 222",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Cédric",
      lastname: " BEULLENS",
      number: " 221",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      firstname: " Tom",
      lastname: " DEVRIENDT",
      number: " 217",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Danny",
      lastname: " VAN POPPEL",
      number: " 216",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Xandro",
      lastname: " MEURISSE",
      number: " 215",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Maurits",
      lastname: " LAMMERTINK",
      number: " 214",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Wesley",
      lastname: " KREDER",
      number: " 213",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Aimé",
      lastname: " DE GENDT",
      number: " 212",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Jan",
      lastname: " BAKELANTS",
      number: " 211",
      team: " Circus - Wanty Gobert",
    },
    {
      firstname: " Tom",
      lastname: " WIRTGEN",
      number: " 207",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Lionel",
      lastname: " TAMINIAUX",
      number: " 206",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Joel",
      lastname: " SUTER",
      number: " 205",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Ludovic",
      lastname: " ROBEET",
      number: " 204",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Baptiste",
      lastname: " PLANCKAERT",
      number: " 203",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Arjen",
      lastname: " LIVYNS",
      number: " 202",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Sean",
      lastname: " DE BIE",
      number: " 201",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      firstname: " Julien",
      lastname: " VERMOTE",
      number: " 197",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Cyril",
      lastname: " LEMOINE",
      number: " 195",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Christophe",
      lastname: " LAPORTE",
      number: " 194",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Eddy",
      lastname: " FINÉ",
      number: " 193",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Dimitri",
      lastname: " CLAEYS",
      number: " 192",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Piet",
      lastname: " ALLEGAERT",
      number: " 191",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Rui",
      lastname: " OLIVEIRA",
      number: " 187",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Ivo",
      lastname: " OLIVEIRA",
      number: " 186",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Marco",
      lastname: " MARCATO",
      number: " 185",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Vegard Stake",
      lastname: " LAENGEN",
      number: " 184",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Sven Erik",
      lastname: " BYSTRØM",
      number: " 183",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Tom",
      lastname: " BOHLI",
      number: " 182",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Alexander",
      lastname: " KRISTOFF",
      number: " 181",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Artyom",
      lastname: " ZAKHAROV",
      number: " 177",
      team: " Astana Pro Team",
    },
    {
      firstname: " Alexey",
      lastname: " LUTSENKO",
      number: " 176",
      team: " Astana Pro Team",
    },
    {
      firstname: " Hugo",
      lastname: " HOULE",
      number: " 175",
      team: " Astana Pro Team",
    },
    {
      firstname: " Dmitriy",
      lastname: " GRUZDEV",
      number: " 174",
      team: " Astana Pro Team",
    },
    {
      firstname: " Yevgeniy",
      lastname: " GIDICH",
      number: " 173",
      team: " Astana Pro Team",
    },
    {
      firstname: " Laurens",
      lastname: " DE VREESE",
      number: " 172",
      team: " Astana Pro Team",
    },
    {
      firstname: " Zhandos",
      lastname: " BIZHIGITOV",
      number: " 171",
      team: " Astana Pro Team",
    },
    {
      firstname: " Joris",
      lastname: " NIEUWENHUIS",
      number: " 167",
      team: " Team Sunweb",
    },
    {
      firstname: " Casper",
      lastname: " PEDERSEN",
      number: " 166",
      team: " Team Sunweb",
    },
    {
      firstname: " Søren",
      lastname: " KRAGH ANDERSEN",
      number: " 165",
      team: " Team Sunweb",
    },
    {
      firstname: " Nils",
      lastname: " EEKHOFF",
      number: " 164",
      team: " Team Sunweb",
    },
    {
      firstname: " Cees",
      lastname: " BOL",
      number: " 163",
      team: " Team Sunweb",
    },
    {
      firstname: " Nikias",
      lastname: " ARNDT",
      number: " 162",
      team: " Team Sunweb",
    },
    {
      firstname: " Tiesj",
      lastname: " BENOOT",
      number: " 161",
      team: " Team Sunweb",
    },
    {
      firstname: " Dylan",
      lastname: " VAN BAARLE",
      number: " 157",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Luke",
      lastname: " ROWE",
      number: " 156",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Gianni",
      lastname: " MOSCON",
      number: " 155",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Ethan",
      lastname: " HAYTER",
      number: " 154",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Owain",
      lastname: " DOULL",
      number: " 153",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Leonardo",
      lastname: " BASSO",
      number: " 152",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Michał",
      lastname: " KWIATKOWSKI",
      number: " 151",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Rasmus",
      lastname: " TILLER",
      number: " 147",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Reinardt",
      lastname: " JANSE VAN RENSBURG",
      number: " 146",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Michael",
      lastname: " VALGREN",
      number: " 145",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Michael",
      lastname: " GOGL",
      number: " 144",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Ryan",
      lastname: " GIBBONS",
      number: " 143",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Samuele",
      lastname: " BATTISTELLA",
      number: " 142",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Edvald",
      lastname: " BOASSON HAGEN",
      number: " 141",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Eduard",
      lastname: " PRADES",
      number: " 137",
      team: " Movistar Team",
    },
    {
      firstname: " Sebastián",
      lastname: " MORA",
      number: " 136",
      team: " Movistar Team",
    },
    {
      firstname: " Mathias",
      lastname: " NORSGAARD",
      number: " 135",
      team: " Movistar Team",
    },
    {
      firstname: " Johan",
      lastname: " JACOBS",
      number: " 134",
      team: " Movistar Team",
    },
    {
      firstname: " Juri",
      lastname: " HOLLMANN",
      number: " 133",
      team: " Movistar Team",
    },
    {
      firstname: " Gabriel",
      lastname: " CULLAIGH",
      number: " 132",
      team: " Movistar Team",
    },
    {
      firstname: " Jürgen",
      lastname: " ROELANDTS",
      number: " 131",
      team: " Movistar Team",
    },
    {
      firstname: " Barnabás",
      lastname: " PEÁK",
      number: " 127",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Luka",
      lastname: " MEZGEC",
      number: " 126",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Alexander",
      lastname: " KONYCHEV",
      number: " 125",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Christopher",
      lastname: " JUUL-JENSEN",
      number: " 124",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Alex",
      lastname: " EDMONDSON",
      number: " 123",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Luke",
      lastname: " DURBRIDGE",
      number: " 122",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Jack",
      lastname: " BAUER",
      number: " 121",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Dylan",
      lastname: " TEUNS",
      number: " 117",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Heinrich",
      lastname: " HAUSSLER",
      number: " 116",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Marco",
      lastname: " HALLER",
      number: " 115",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Iván",
      lastname: " GARCÍA CORTINA",
      number: " 114",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Chun Kai",
      lastname: " FENG",
      number: " 113",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Sonny",
      lastname: " COLBRELLI",
      number: " 112",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Mark",
      lastname: " CAVENDISH",
      number: " 111",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Tom",
      lastname: " VAN ASBROECK",
      number: " 107",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Travis",
      lastname: " MCCABE",
      number: " 106",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Hugo",
      lastname: " HOFSTETTER",
      number: " 105",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " André",
      lastname: " GREIPEL",
      number: " 104",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Guillaume",
      lastname: " BOIVIN",
      number: " 103",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Jenthe",
      lastname: " BIERMANS",
      number: " 102",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Nils",
      lastname: " POLITT",
      number: " 101",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Jake",
      lastname: " STEWART",
      number: " 97",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Valentin",
      lastname: " MADOUAS",
      number: " 96",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Tobias",
      lastname: " LUDVIGSSON",
      number: " 95",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Fabian",
      lastname: " LIENHARD",
      number: " 94",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Kevin",
      lastname: " GENIETS",
      number: " 93",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Alexys",
      lastname: " BRUNEL",
      number: " 92",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Stefan",
      lastname: " KÜNG",
      number: " 91",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Edward",
      lastname: " THEUNS",
      number: " 87",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Jasper",
      lastname: " STUYVEN",
      number: " 86",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Toms",
      lastname: " SKUJIŅŠ",
      number: " 85",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Kiel",
      lastname: " REIJNEN",
      number: " 84",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Ryan",
      lastname: " MULLEN",
      number: " 83",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Alex",
      lastname: " KIRSCH",
      number: " 82",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Mads",
      lastname: " PEDERSEN",
      number: " 81",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Francisco José",
      lastname: " VENTOSO",
      number: " 77",
      team: " CCC Team",
    },
    {
      firstname: " Guillaume",
      lastname: " VAN KEIRSBULCK",
      number: " 76",
      team: " CCC Team",
    },
    {
      firstname: " Nathan",
      lastname: " VAN HOOYDONCK",
      number: " 75",
      team: " CCC Team",
    },
    {
      firstname: " Gijs",
      lastname: " VAN HOECKE",
      number: " 74",
      team: " CCC Team",
    },
    {
      firstname: " Michael",
      lastname: " SCHÄR",
      number: " 73",
      team: " CCC Team",
    },
    {
      firstname: " Jonas",
      lastname: " KOCH",
      number: " 72",
      team: " CCC Team",
    },
    {
      firstname: " Matteo",
      lastname: " TRENTIN",
      number: " 71",
      team: " CCC Team",
    },
    {
      firstname: " Maximilian",
      lastname: " SCHACHMANN",
      number: " 67",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Lukas",
      lastname: " PÖSTLBERGER",
      number: " 66",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Daniel",
      lastname: " OSS",
      number: " 65",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Gregor",
      lastname: " MÜHLBERGER",
      number: " 64",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Oscar",
      lastname: " GATTO",
      number: " 63",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Jempy",
      lastname: " DRUCKER",
      number: " 62",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Marcus",
      lastname: " BURGHARDT",
      number: " 61",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Gianni",
      lastname: " VERMEERSCH",
      number: " 57",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Otto",
      lastname: " VERGAERDE",
      number: " 56",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Petr",
      lastname: " VAKOČ",
      number: " 55",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Scott",
      lastname: " THWAITES",
      number: " 54",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Jonas",
      lastname: " RICKAERT",
      number: " 53",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Dries",
      lastname: " DE BONDT",
      number: " 52",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Mathieu",
      lastname: " VAN DER POEL",
      number: " 51",
      team: " Alpecin-Fenix",
    },
    {
      firstname: " Maarten",
      lastname: " WYNANTS",
      number: " 47",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Mike",
      lastname: " TEUNISSEN",
      number: " 46",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Timo",
      lastname: " ROOSEN",
      number: " 45",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Bert-Jan",
      lastname: " LINDEMAN",
      number: " 44",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Amund Grøndahl",
      lastname: " JANSEN",
      number: " 43",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Pascal",
      lastname: " EENKHOORN",
      number: " 42",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Wout",
      lastname: " VAN AERT",
      number: " 41",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Stijn",
      lastname: " VANDENBERGH",
      number: " 37",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Lawrence",
      lastname: " NAESEN",
      number: " 36",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Alexis",
      lastname: " GOUGEARD",
      number: " 35",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Julien",
      lastname: " DUVAL",
      number: " 34",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Silvan",
      lastname: " DILLIER",
      number: " 33",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Romain",
      lastname: " BARDET",
      number: " 32",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Oliver",
      lastname: " NAESEN",
      number: " 31",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Jelle",
      lastname: " WALLAYS",
      number: " 27",
      team: " Lotto Soudal",
    },
    {
      firstname: " Brent",
      lastname: " VAN MOER",
      number: " 26",
      team: " Lotto Soudal",
    },
    {
      firstname: " Nikolas",
      lastname: " MAES",
      number: " 25",
      team: " Lotto Soudal",
    },
    {
      firstname: " Roger",
      lastname: " KLUGE",
      number: " 24",
      team: " Lotto Soudal",
    },
    {
      firstname: " John",
      lastname: " DEGENKOLB",
      number: " 23",
      team: " Lotto Soudal",
    },
    {
      firstname: " Jasper",
      lastname: " DE BUYST",
      number: " 22",
      team: " Lotto Soudal",
    },
    {
      firstname: " Tim",
      lastname: " WELLENS",
      number: " 21",
      team: " Lotto Soudal",
    },
    {
      firstname: " Zdeněk",
      lastname: " ŠTYBAR",
      number: " 17",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Florian",
      lastname: " SÉNÉCHAL",
      number: " 16",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Yves",
      lastname: " LAMPAERT",
      number: " 15",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Bob",
      lastname: " JUNGELS",
      number: " 14",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Tim",
      lastname: " DECLERCQ",
      number: " 13",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Kasper",
      lastname: " ASGREEN",
      number: " 12",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Julian",
      lastname: " ALAPHILIPPE",
      number: " 11",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Tom",
      lastname: " SCULLY",
      number: " 7",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Logan",
      lastname: " OWEN",
      number: " 6",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Sebastian",
      lastname: " LANGEVELD",
      number: " 5",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Jens",
      lastname: " KEUKELEIRE",
      number: " 4",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Mitchell",
      lastname: " DOCKER",
      number: " 3",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Sep",
      lastname: " VANMARCKE",
      number: " 2",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Alberto",
      lastname: " BETTIOL",
      number: " 1",
      team: " EF Pro Cycling",
    },
  ]

  const race = "Flanders"

  return (
    <Racepage title="Tour of Flanders 2020" startlist={startlist} race={race} />
  )
}

export default Flanders2020
